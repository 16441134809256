import { observable, action, computed } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { GetApplicationAppConfig } from '@kurtosys/ksys-api-client/dist/requests/root/GetApplicationAppConfig';
import { IRequestOptions } from '@kurtosys/ksys-api-client/dist/models/IRequestOptions';
import { ListApplications } from '@kurtosys/ksys-api-client/dist/requests/applicationManager/ListApplications';
import { Initialize } from '@kurtosys/ksys-api-client/dist/requests/apps/Initialize';
import { GetApplicationClientConfiguration } from '@kurtosys/ksys-api-client/dist/requests/applicationManager/GetApplicationClientConfiguration';

export class KurtosysApiStore extends StoreBase {

	@computed
	get getAppConfig(): GetApplicationAppConfig {
		return new GetApplicationAppConfig(this.wrappedToken, this.options);
	}

	@computed
	get listApplications(): ListApplications {
		return new ListApplications(this.wrappedToken, this.options);
	}

	@computed
	get initializeApps(): Initialize {
		return new Initialize(this.wrappedToken, this.options);
	}

	@computed
	get getApplicationClientConfiguration(): GetApplicationClientConfiguration {
		return new GetApplicationClientConfiguration(this.wrappedToken, this.options);
	}

	@action
	async initialize(): Promise<void> {

	}

	@observable token: string = '';
	@computed
	get wrappedToken(): { value: string } {
		return { value: this.token };
	}
	@computed
	get options(): IRequestOptions<any> {
		return {
			baseUrl: this.serviceUrl,
		};
	}

	get serviceUrl(): string {
		const { appStore } = this.storeContext;
		if (appStore.authentication && appStore.authentication.serviceUrl && appStore.authentication.serviceUrl.length > 0) {
			return appStore.authentication.serviceUrl;
		}
		if (appStore.configuration && appStore.configuration.core && appStore.configuration.core.serviceUrl) {
			return appStore.configuration.core.serviceUrl;
		}
		return this.getBaseAddress();
	}

	getBaseAddress(suffix: string = 'services/') {
		return this.manifest.getBaseUrl(suffix);
	}

}