import { StoreContext } from '../../../configuration/StoreContext';
import { observable, action, computed } from 'mobx';
import { ITranslation, ITranslations } from '../../../models/commonTypes';
import { helpers } from '@kurtosys/ksys-app-template';
import { StoreBase } from '../../../common/StoreBase';
import { getHydrationPoint } from '../../../initialize';


export class TranslationStore extends StoreBase {

	@action
	async initialize(): Promise<void> {

	}

	@observable.ref translationRecords: ITranslation[] = [];
	@computed
	get translationHelper(): helpers.TranslationHelper {
		return new helpers.TranslationHelper(this.targetTranslations, this.fallbackTranslations);
	}

	@computed
	get baseCulture() {
		let baseCulture = 'Default';
		const appConfig = this.storeContext.appStore.configuration;
		if (
			appConfig &&
			appConfig.culture &&
			appConfig.culture.base
		) {
			baseCulture = appConfig.culture.base;
		}
		return baseCulture;
	}

	@computed
	get defaultCulture() {
		let defaultCulture = 'en-GB';
		const appConfig = this.storeContext.appStore.configuration;
		if (
			appConfig &&
			appConfig.culture &&
			appConfig.culture.default
		) {
			defaultCulture = appConfig.culture.default;
		}
		return defaultCulture;
	}

	@computed
	get culture() {
		let culture = this.defaultCulture;
		const appParams = this.storeContext.appStore.appParamsHelper;
		if (
			appParams &&
			appParams.inputs &&
			appParams.inputs.culture
		) {
			culture = appParams.inputs.culture;
		}
		return culture;
	}

	// Builds a translation dictionary object for the current culture from all translation data. E.g:
	// { 'phrase': 'translation', 'phrase': 'translation', etc }
	@computed
	get targetTranslations() {
		return this.getTranslationsDictionary(this.translationRecords, this.culture);
	}

	@computed
	get fallbackTranslations() {
		return this.getTranslationsDictionary(this.translationRecords, this.defaultCulture);
	}

	getTranslationsDictionary(translationRecords: ITranslation[], targetCulture: string) {
		const response: ITranslations = translationRecords
			.filter((translationRecord) => {
				return (
					translationRecord.translationCulture === targetCulture &&
					translationRecord.culture === this.baseCulture
				);
			})
			.reduce((acc: ITranslations, translationRecord) => {
				const { phrase, translation } = translationRecord;
				acc[phrase] = translation;
				return acc;
			}, {});

		return response;
	}

	@action
	async loadTranslations() {
		if (this.storeContext) {
			const { kurtosysApiStore } = this.storeContext;
			const hydrationPoint = getHydrationPoint();
			if (hydrationPoint.translations) {
				this.translationRecords = hydrationPoint.translations;
			}
		}
	}

	translate = (key: string, placeholders?: any) => {
		return this.translationHelper.translate(key, placeholders);
	}
}