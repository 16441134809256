import { IConfiguration } from '../models/app/IConfiguration';

export const CONFIGURATION: IConfiguration = {
	appsRequestOptons: {
		combineJs: false,
	},
	appsExternalScriptOptions: {
		defer: true,
	},
	appVersions: {
		'ksys-app-commentary': 'v1.5.0',
		'ksys-app-attestation': 'v2.2.0',
		'ksys-app-page-header': 'v1.4.0',
	},
	appsRenderOptons: {
		groups: [
			{
				key: 'aboveFold',
				apps: [
					{
						templateId: 'ksys-app-attestation',
					},
					{
						templateId: 'ksys-app-page-header',
					},
					{
						templateId: 'ksys-app-commentary',
					},
				],
			},
		],
	},
};
