import { Manifest } from './configuration/Manifest';
import { loadExternalScripts } from './utils/scripts';
import { TemplateStore } from './components/App/stores/TemplateStore';
import { IAppsHydration } from './models/app/IAppsHydration';
const manifest = new Manifest();

export const HYDRATION_KEY = '__ksys-app-hydration__';
export function getHydrationPoint(): IAppsHydration {
	if (!(window as any)[HYDRATION_KEY]) {
		(window as any)[HYDRATION_KEY] = {};
	}
	return (window as any)[HYDRATION_KEY];
}

export const TEMPLATE_STORE = new TemplateStore(manifest);

async function initialize() {
	await TEMPLATE_STORE.initialize();
	loadExternalScripts(manifest, manifest.externalScripts, () => {
		require('./start');
	}, TEMPLATE_STORE.externalScriptsBasePath || manifest.getBaseUrl(''), TEMPLATE_STORE.cacheOptions);
}

initialize();