import { StoreContext } from '../configuration/StoreContext';
import { Manifest } from '../configuration/Manifest';
import { observable } from 'mobx';

export abstract class StoreBase {
	storeContext: StoreContext;
	manifest: Manifest;
	@observable isInitialized: boolean = false;
	constructor(storeContext: StoreContext) {
		this.storeContext = storeContext;
		this.manifest = new Manifest();
	}
	abstract async initialize(): Promise<void>;
}