import { appsManager } from '@kurtosys/ksys-app-template';
import * as serviceWorker from './serviceWorker';
import { Manifest } from './configuration/Manifest';
import { getStoreContextForElement } from './utils/store';
import { EVENTS } from './utils/initialize';

const manifest = new Manifest();
const URL = window.location.href;

function initialize() {
	const elements = appsManager.utils.getElementsForManifest(manifest);
	for (const element of elements) {
		getStoreContextForElement(element, URL, manifest);
	}
}

function embedAddedHandler(event: Event) {
	const elements = appsManager.utils.getElementsForManifest(manifest);
	for (const element of elements) {
		const storeContext = getStoreContextForElement(element, URL, manifest);
		storeContext.appStore.addEmbed();
	}
}

export function addEventListeners() {
	removeEventListeners();
	document.addEventListener(EVENTS.KSYS_APP_MANAGER_EMBED_ADDED, embedAddedHandler);
}

export function removeEventListeners() {
	document.removeEventListener(EVENTS.KSYS_APP_MANAGER_EMBED_ADDED, embedAddedHandler);
}

initialize();
addEventListeners();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
