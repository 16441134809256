import { helpers, appsManager, models, utils } from '@kurtosys/ksys-app-template';
import {
	IAppsResponse,
	IAppsRequest,
	IAppsRender,
	TExternalScriptOptions,
	CacheOptions,
} from '../../../models/commonTypes';
import { StoreBase } from '../../../common/StoreBase';
import { CONFIGURATION } from '../../../configuration/development.config';
import { STYLES } from '../../../configuration/development.styles';
import { AUTHENTICATION } from '../../../configuration/development.authentication';
import { APPLICATION_CLIENT_CONFIGURATION_IDS } from '../../../configuration/development.applicationClientConfigurationIds';
import { APPLICATION_CLIENT_CONFIGURATIONS } from '../../../configuration/development.applicationClientConfigurations';
import { IConfiguration } from '../../../models/app/IConfiguration';
import { IInputs } from '../../../models/app/IInputs';
import { IStyles } from '../../../models/app/IStyles';
import { observable, computed, action } from 'mobx';
import { StoreContext } from '../../../configuration/StoreContext';
import { IGetApplicationAppConfigResponseBody } from '@kurtosys/ksys-api-client/dist/models/requests/config/IGetApplicationAppConfigResponseBody';
import { Manifest } from '../../../configuration/Manifest';
import { TEMPLATE_STORE, HYDRATION_KEY } from '../../../initialize';
import { IAppsRequestOptions } from '../../../models/IApps';
import { IApplication } from '@kurtosys/ksys-api-client/dist/models/requests/applicationManager/IApplication';
import { ICoreConfigurations } from '../../../models/app/ICoreConfigurations';
import { triggerEvent } from '../../../utils/initialize';
/* [Component: appStoreComponentImport] */

export class AppStore extends StoreBase {
	appParamsHelper: helpers.AppParamsHelper<IInputs, IConfiguration, IStyles>;
	appHydration: appsManager.AppHydration;
	appsManager: appsManager.AppsManager;
	@observable.ref rawConfiguration: Partial<IConfiguration> = {};
	@observable.ref rawStyles: Partial<IStyles> = {};
	@observable.ref authentication: models.appsInitialize.IAppAuthentication | undefined;
	@observable.ref applicationClientConfigurationIds: number[] | undefined;
	@observable.ref applicationClientConfigurations: models.api.applicationManager.IApplicationClientConfiguration[] = [];

	// App loading states
	@observable isBootstrapped = false;

	constructor(element: HTMLElement | undefined, public url: string, storeContext: StoreContext, public manifest: Manifest) {
		super(storeContext);
		this.appParamsHelper = new helpers.AppParamsHelper(element, url);
		this.appHydration = new appsManager.AppHydration(HYDRATION_KEY);
		this.appsManager = new appsManager.AppsManager(this.manifest, this.appHydration, TEMPLATE_STORE.appScriptsBasePath);
	}

	@computed
	get appVersions(): appsManager.models.IAppVersions {
		return (this.configuration && this.configuration.appVersions) || {};
	}

	@computed
	get appsRequestOptions(): IAppsRequestOptions {
		return (this.configuration && this.configuration.appsRequestOptons) || {};
	}

	@computed
	get appsExternalScriptOptions(): TExternalScriptOptions {
		return (this.configuration && this.configuration.appsExternalScriptOptions) || {};
	}

	@computed
	get assetCacheOptions(): CacheOptions | undefined {
		return (this.configuration && this.configuration.core && this.configuration.core.assetCacheOptions) || undefined;
	}

	@computed
	get appsRenderOptions(): IAppsRender | undefined {
		return (this.configuration && this.configuration.appsRenderOptons) || undefined;
	}

	@computed
	get isDevelopment(): boolean {
		return this.manifest.isDevelopment;
	}

	@action
	async initialize() {
		const { kurtosysApiStore } = this.storeContext;

		const promises: Promise<any>[] = [
			this.getAppConfig(),
			TEMPLATE_STORE.initialize(),
		];

		const [appConfigResponse, templateStoreResponse] = await Promise.all(promises);
		this.appsManager = new appsManager.AppsManager(this.manifest, this.appHydration, TEMPLATE_STORE.appScriptsBasePath);

		if (appConfigResponse) {
			this.authentication = appConfigResponse.authentication;
			const token = (this.authentication && this.authentication.token) || (this.configuration && this.configuration.token) || '';
			kurtosysApiStore.token = token;
			this.applicationClientConfigurationIds = appConfigResponse.applicationClientConfigurationIds;
			this.rawConfiguration = await this.buildConfiguration(appConfigResponse.applicationConfiguration as any as IConfiguration);
			this.rawStyles = appConfigResponse.applicationStyles as any as IStyles;
			this.docReady(async () => {
				this.renderSkeletonLoaders();

				this.isBootstrapped = true;
				const { groups = [], ...appsRenderOptions } = this.appsRenderOptions || {};
				if (groups && groups.length > 0) {
					for (const group of groups) {
						const initializeResponse = await this.appsManager.initialize({
							group,
							appsRenderOptions: (appsRenderOptions as IAppsRender),
							url: this.url,
							appVersions: this.appVersions,
							appsRequestOptions: this.appsRequestOptions,
							initializeAppRequests: this.initializeAppRequests,
							getApplications: this.getApplications,
							externalScriptOptions: this.appsExternalScriptOptions,
							assetRegisterCacheOptions: this.assetCacheOptions,
						});
						const { elements } = initializeResponse;
						this.triggerInitializeEventForElements(elements);
					}
				}
				const initializeResponse = await this.appsManager.initialize({
					appsRenderOptions: (appsRenderOptions as IAppsRender),
					url: this.url,
					appVersions: this.appVersions,
					appsRequestOptions: this.appsRequestOptions,
					initializeAppRequests: this.initializeAppRequests,
					getApplications: this.getApplications,
					externalScriptOptions: this.appsExternalScriptOptions,
					assetRegisterCacheOptions: this.assetCacheOptions,
				});
				const { elements } = initializeResponse;
				this.triggerInitializeEventForElements(elements);
			});
		}
	}

	async buildConfiguration(configuration: IConfiguration): Promise<IConfiguration> {
		let response: IConfiguration = {};
		const applicationClientConfigurationIds = this.applicationClientConfigurationIds || [];
		if (applicationClientConfigurationIds && applicationClientConfigurationIds.length > 0) {
			if (this.isDevelopment) {
				if (this.applicationClientConfigurations.length === 0) {
					this.applicationClientConfigurations = [...APPLICATION_CLIENT_CONFIGURATIONS];
				}
				const results = applicationClientConfigurationIds.map((id) => {
					const applicationClientConfiguration = this.applicationClientConfigurations.find(a => a.applicationClientConfigurationId === id);
					return applicationClientConfiguration;
				});
				const errors = results.filter(result => !result);
				if (errors.length === 0) {
					response = utils.object.deepMergeObjects(response, ...results.map(result => (result && result.configuration) || {}));
				}
				else {
					console.warn(`applicationClientConfigurationIds don't have all available application client configurations in the APPLICATION_CLIENT_CONFIGURATIONS collection`, { APPLICATION_CLIENT_CONFIGURATIONS, APPLICATION_CLIENT_CONFIGURATION_IDS });
				}
			}
			else {
				if (this.authentication && this.authentication.token) {
					const promises: Promise<models.api.applicationManager.IApplicationClientConfiguration>[] = [];
					for (const applicationClientConfigurationId of applicationClientConfigurationIds) {
						const existingApplicationClientConfiguration = this.applicationClientConfigurations.find(a => a.applicationClientConfigurationId === applicationClientConfigurationId);
						if (existingApplicationClientConfiguration) {
							promises.push(new Promise((resolve) => {
								resolve(existingApplicationClientConfiguration);
							}));
						}
						const { kurtosysApiStore } = this.storeContext;
						const queryString: any = {
							applicationClientConfigurationId,
						};
						promises.push(
							kurtosysApiStore.getApplicationClientConfiguration.execute({
								queryString,
							}),
						);
					}
					const results = await Promise.all<models.api.applicationManager.IApplicationClientConfiguration>(promises.map(p => p.catch(e => e)));
					const errors = results.filter(result => (result instanceof Error));
					if (errors.length === 0) {
						const resultsToAdd = results.filter((result) => {
							return !this.applicationClientConfigurations.some(a => a.applicationClientConfigurationId === result.applicationClientConfigurationId);
						});
						if (resultsToAdd && resultsToAdd.length > 0) {
							this.applicationClientConfigurations = [
								...this.applicationClientConfigurations,
								...resultsToAdd,
							];
						}
						response = utils.object.deepMergeObjects(response, ...results.map(result => result.configuration));
					}
					else {
						console.warn({ buildConfigurationErrors: errors });
					}
				}
			}
		}
		if (configuration) {
			response = utils.object.deepMergeObjects(response, configuration);
		}
		return response;
	}

	@action
	async addEmbed() {
		const { groups = [], ...appsRenderOptions } = this.appsRenderOptions || {};

		const initializeResponse = await this.appsManager.initialize({
			appsRenderOptions: (appsRenderOptions as IAppsRender),
			url: this.url,
			appVersions: this.appVersions,
			appsRequestOptions: this.appsRequestOptions,
			initializeAppRequests: this.initializeAppRequests,
			getApplications: this.getApplications,
			externalScriptOptions: this.appsExternalScriptOptions,
			assetRegisterCacheOptions: this.assetCacheOptions,
		});
		const { elements } = initializeResponse;
		this.triggerInitializeEventForElements(elements);
	}

	triggerInitializeEventForElements = (elements: HTMLElement[]) => {
		const eventName = 'ksys-app-manager-initialized';
		for (const element of elements) {
			triggerEvent(eventName, element);
		}
	}

	docReady = (fn: any) => {
		if (fn && typeof fn === 'function') {
			// see if DOM is already available
			if (document.readyState === 'complete' || document.readyState === 'interactive') {
				// call on next available tick
				setTimeout(fn, 1);
			}
			else {
				document.addEventListener('DOMContentLoaded', fn);
			}
		}
	}

	@computed
	get coreConfigurations(): ICoreConfigurations {
		let response: ICoreConfigurations | undefined;
		if (this.configuration && this.configuration.core) {
			response = this.configuration.core;
		}
		if (!response) {
			response = {

			};
		}
		return response;
	}

	renderSkeletonLoaders() {
		const elements = Array.from(document.querySelectorAll('[data-ksys-app-template-id]')) as any as HTMLElement[];
		const skeletonLoadersConfig = (this.coreConfigurations && this.coreConfigurations.skeletonLoaders);
		if (skeletonLoadersConfig) {
			const { loaders = [], variables } = skeletonLoadersConfig;
			for (const skeletonLoaderConfig of loaders) {
				if (skeletonLoaderConfig) {
					const { id, configurationKey = 'default' } = skeletonLoaderConfig;

					const targetElement = elements.find((element) => {
						if (element && element.dataset && element.dataset.ksysAppTemplateId === id) {
							const elementConfigurationKey = element.dataset.configurationKey;
							if (configurationKey === 'default' && !elementConfigurationKey) {
								return true;
							}
							if (elementConfigurationKey && elementConfigurationKey === configurationKey) {
								return true;
							}
						}
						return false;
					});
					if (targetElement) {
						const skeletonLoader = new appsManager.SkeletonLoader(skeletonLoaderConfig, variables);
						skeletonLoader.render(targetElement);
					}
				}
			}
		}
	}

	initializeAppRequests = async (appsRequests: IAppsRequest[]): Promise<IAppsResponse[]> => {
		const { kurtosysApiStore } = this.storeContext;
		const promises: Promise<any>[] = appsRequests.map(appsRequest => kurtosysApiStore.initializeApps.execute({
			method: 'GET',
			queryString: { request: JSON.stringify(appsRequest) },
		}).catch(e => e));
		const appsResponses = await Promise.all(promises);
		return appsResponses;
	}

	getApplications = async (): Promise<IApplication[]> => {
		try {
			const applicationsResponse = await this.storeContext.kurtosysApiStore.listApplications.execute({
				body: {},
			});
			const { data: applications } = applicationsResponse;
			return applications;
		}
		catch (ex) {
			console.warn(`Exception in getApplications: `, ex);
		}
		return [];
	}

	@action
	async getAppConfig(): Promise<IGetApplicationAppConfigResponseBody> {
		const appFromHydration = this.appHydration.getAppHydration(this.manifest, this.appParamsHelper);
		if (appFromHydration) {
			const { applicationConfiguration, applicationStyles, authentication, applicationClientConfigurationIds } = appFromHydration;
			return {
				applicationConfiguration,
				applicationStyles,
				authentication,
				applicationClientConfigurationIds,
			};
		}
		// We want to use the hard coded configuration when developing locally
		// otherwise fetch it
		let response: IGetApplicationAppConfigResponseBody = {
			applicationConfiguration: CONFIGURATION,
			applicationStyles: STYLES,
			authentication: AUTHENTICATION,
			applicationClientConfigurationIds: APPLICATION_CLIENT_CONFIGURATION_IDS,
		};
		if (!this.isDevelopment) {
			response = await this.getAppConfigFromApi();
		}
		return response;
	}

	@action
	async getAppConfigFromApi(): Promise<IGetApplicationAppConfigResponseBody> {
		const configurationKey = (this.appParamsHelper.rawAppParams && this.appParamsHelper.rawAppParams.configurationKey) || 'default';
		const { kurtosysApiStore } = this.storeContext;
		return await kurtosysApiStore.getAppConfig.execute({
			urlParams: [configurationKey],
		});
	}

	@computed
	get assetsBaseUrl(): string {
		const { kurtosysApiStore } = this.storeContext;
		return kurtosysApiStore.getBaseAddress('');
	}

	@computed
	get configuration(): IConfiguration | undefined {
		if (this.rawConfiguration) {
			return this.rawConfiguration as IConfiguration;
		}
	}

	getInput(inputKey: keyof IInputs) {
		return this.appParamsHelper.inputs && this.appParamsHelper.inputs[inputKey];
	}

}