import { models } from '@kurtosys/ksys-app-template';
import { IAppConfigurationBase } from '../models/app/IAppConfigurationBase';

export const APPLICATION_CLIENT_CONFIGURATIONS: models.api.applicationManager.IApplicationClientConfiguration[] = [
	{
		applicationClientConfigurationId: 1,
		configuration: {
			culture: {
				base: 'Default',
				default: 'en-GB',
			},
			core: {
				serviceUrl: 'https://api-rel.fundpress.io',
				assetCacheOptions: {
					maxAge: 2000,
				},
				skeletonLoaders: {
					variables: [
						{
							key: 'fillColor',
							value: '#dddddd',
						},
					],
					loaders: [
						{
							id: 'ksys-app-page-header',
							breakpoints: [
								{
									maxWidthInPixels: 999,
									style: {
										height: '100px',
									},
									children: [
										{
											label: 'title',
											fill: '{fillColor}',
											x: '0%',
											y: '0px',
											height: '46px',
											width: '75%',
										},
									],
								},

								{
									minWidthInPixels: 1000,
									style: {
										height: '167px',
									},
									children: [
										{
											label: 'title',
											fill: '{fillColor}',
											x: '0%',
											y: '0px',
											height: '46px',
											width: '75%',
										},
										{
											label: 'category',
											fill: '{fillColor}',
											x: '100%',
											y: '0px',
											height: '58px',
											width: '20%',
										},
										{
											label: 'isin',
											fill: '{fillColor}',
											x: '0%',
											y: '54px',
											height: '24px',
											width: '13.66554054054054%',
										},
										{
											label: 'separator',
											fill: '{fillColor}',
											x: '15.016891891891893%',
											y: '54px',
											height: '24px',
											width: '0.3744369369369369%',
										},
										{
											label: 'ticker',
											fill: '{fillColor}',
											x: '16.74268018018018%',
											y: '54px',
											height: '24px',
											width: '20.49408783783784%',
										},
										{
											label: 'dropdown',
											fill: '{fillColor}',
											x: '0%',
											y: '116px',
											height: '51px',
											width: '24.0990990990991%',
										},
										{
											label: 'search',
											fill: '{fillColor}',
											x: '100%',
											y: '116px',
											height: '48px',
											width: '72.47747747747748%',
										},
									],
								},
							],
						},
						{
							breakpoints: [
								{
									children: [
										{
											fill: '{fillColor}',
											height: '16px',
											label: 'button',
											width: '100%',
											x: '0%',
											y: '5px',
										},
									],
									minWidthInPixels: 0,
									style: {
										height: '30px',
									},
								},
							],
							id: 'ksys-app-data-download',
						},
					],
				},
			},
		} as IAppConfigurationBase,
		name: 'root',
		applicationClientConfigurationTypeId: 1,
	},
];