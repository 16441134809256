import { helpers } from '@kurtosys/ksys-app-template';
import { StoreContext } from '../configuration/StoreContext';
import { Manifest } from '../configuration/Manifest';
import { TEMPLATE_STORE } from '../initialize';

export function getStoreContextForElement(element: HTMLElement | undefined, url: string, manifest: Manifest) {
	const storeKey = getStoreKey(element, url, manifest);
	if (!(window as any)[storeKey]) {
		(window as any)[storeKey] = new StoreContext(element, url, TEMPLATE_STORE, manifest);
	}
	const storeContext = (window as any)[storeKey] as StoreContext;
	return storeContext;
}

export function getStoreKey(element: HTMLElement | undefined, url: string, manifest: Manifest) {
	// Refactor to allow for static retrieval of the appParams
	const helper = new helpers.AppParamsHelper(element, url);
	const appParams = helper.getAppParamsFromElement();
	// Check if there is an explicit key that is supposed to be used for the store
	// that is defined on the element
	let manifestStoreKey = `${ manifest.storeKey }-default`;
	if (appParams.configurationKey) {
		manifestStoreKey = `${ manifest.storeKey }-${ appParams.configurationKey }`;
	}
	return appParams.storeKey || manifestStoreKey;
}