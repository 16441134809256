import { Manifest } from '../configuration/Manifest';
import { helpers, appsManager } from '@kurtosys/ksys-app-template';
import { IStyles } from '../models/app/IStyles';
import { IConfiguration } from '../models/app/IConfiguration';
import { IInputs } from '../models/app/IInputs';
import { IAppRegistry } from '../models/app/IAppRegistry';

export const EVENTS = {
	KSYS_APP_MANAGER_EMBED_ADDED: 'ksys-app-manager-embed-added',
};

function getAppRegistry(): IAppRegistry {
	const key = '__ksys-app-registry__';
	if (!(window as any)[key]) {
		(window as any)[key] = {};
	}
	return (window as any)[key];
}

export function registerAppStart(
	manifest: Manifest,
	appParamsHelper: helpers.AppParamsHelper<IInputs, IConfiguration, IStyles>,
) {
	const appKey = appsManager.utils.getAppKey(manifest, appParamsHelper);
	const appRegistry = getAppRegistry();
	appRegistry[appKey] = {
		appKey,
		status: 'started',
	};
}

export function registerAppLoaded(
	manifest: Manifest,
	appParamsHelper: helpers.AppParamsHelper<IInputs, IConfiguration, IStyles>,
) {
	const appKey = appsManager.utils.getAppKey(manifest, appParamsHelper);
	const appRegistry = getAppRegistry();
	const app = appRegistry[appKey];
	if (app) {
		app.status = 'loaded';
	}
	triggerEvent(`ksysApp-${ appKey }-loaded`);
	setDocDoneIfReady();
}

export function areAllAppsLoaded() {
	const appRegistry = getAppRegistry();
	let allLoaded = true;
	for (const key of Object.keys(appRegistry)) {
		const app = appRegistry[key];
		allLoaded = allLoaded && app.status === 'loaded';
		if (!allLoaded) {
			break;
		}
	}
	return allLoaded;
}

export function setDocDoneIfReady() {
	const allLoaded = areAllAppsLoaded();
	if (allLoaded) {
		(window as any)['docdone'] = true;
		triggerEvent('ksysAppsLoaded');
	}
}

export function triggerEvent(name: string, element?: { dispatchEvent: (event: Event) => boolean }) {
	if (!element) {
		element = document;
	}
	console.info(`event triggered: ${ name }`);
	const event = document.createEvent('Event');
	event.initEvent(name, true, true);
	element.dispatchEvent(event);
}

export function addEmbeddedApps() {
	const managerElement = document.querySelector('[data-ksys-app-template-id="ksys-app-manager"]');
	if (managerElement) {
		const event = document.createEvent('Event');
		event.initEvent(EVENTS.KSYS_APP_MANAGER_EMBED_ADDED, true, true);
		managerElement.dispatchEvent(event);
	}
}
(window as any).ksysAddEmbeddedApps = addEmbeddedApps;
